import React from "react";
import { Link } from "gatsby";
import Layout from "../../components/layout";
import { Container, Row, Col } from "react-bootstrap";
// Images
import { StaticImage } from "gatsby-plugin-image";
import Seo from "../../components/seo";

const BikeRacks = () => {
  return (
    <Layout>
      <Seo
        title="Aparcabicicletas - BIKESAFE"
        description="Nuestra gama de aparcabicicletas puede acomodar desde una hasta 24 bicicletas en un solo rack. Todos nuestros productos son resistentes a la corrosión y brindan seguridad tanto contra las inclemencias del tiempo como los robos."
      />
      <Container>
        <Row>
          <Col sm="12">
            <h1 className="mt-5 mb-5 text-center uppercase">
              Aparcabicicletas
            </h1>
          </Col>
        </Row>
        <Row>
          <Col sm="12">
            <p className="flow-text">
              Nuestra amplia gama de aparcabicicletas está diseñada para
              acomodar desde una hasta 24 bicicletas en un solo rack. Todos
              nuestros productos son resistentes a la corrosión y están
              diseñados para desafiar tanto las condiciones climáticas adversas
              como las amenazas de robo, ofreciendo soluciones seguras y
              duraderas.
            </p>
            <p className="flow-text">
              Explore nuestra selecta gama a continuación para encontrar la
              solución de seguridad perfecta para sus necesidades:
            </p>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row>
          <hr />
          <Col sm="12" lg="6" className="p-2">
            <Link to="/product/61">
              <StaticImage
                placeholder="blurred"
                className="fluid"
                src="../product/61/homepage-61.jpg"
              ></StaticImage>
              <h3 className="uppercase mt-3">
                Aparcabicicletas de dos niveles
              </h3>
              <p>
                ¡Ahorro de espacio, máxima eficiencia y excelente relación
                calidad-precio! El aparcabicicletas de dos niveles es un sistema
                de almacenamiento innovador y fácil de usar que permite...
              </p>
              <p className="shop-now">Solicitar cotización</p>
            </Link>
          </Col>
          <Col sm="12" lg="6" className="p-2">
            <Link to="/product/230">
              <StaticImage
                placeholder="blurred"
                className="fluid"
                src="../product/230/homepage-230.jpg"
              ></StaticImage>
              <h3 className="uppercase mt-3">
                BikeSafe Aparcabicicletas de dos niveles
              </h3>
              <p>
                El aparcabicicletas de dos niveles BikeSafe es un sistema de
                almacenamiento innovador y fácil de usar, diseñado para ahorrar
                espacio y ofrecer la máxima eficiencia y comodidad...
              </p>
              <p className="shop-now">Solicitar cotización</p>
            </Link>
          </Col>
          <Col sm="12" lg="6" className="p-2">
            <Link to="/product/80">
              <StaticImage
                placeholder="blurred"
                className="fluid"
                src="../product/80/homepage-80.jpg"
              ></StaticImage>
              <h3 className="uppercase mt-3">
                Aparcabicicletas vertical independiente
              </h3>
              <p>
                Una variación de nuestro popular aparcabicicletas de pared. Este
                modelo independiente permite a los usuarios aprovechar al máximo
                la superficie del suelo. La estructura se puede fijar con
                tornillos...
              </p>
              <p className="shop-now">Solicitar cotización</p>
            </Link>
          </Col>
          <Col sm="12" lg="6" className="p-2">
            <Link to="/product/88">
              <StaticImage
                placeholder="blurred"
                className="fluid"
                src="../product/88/homepage-88.jpg"
              ></StaticImage>
              <h3 className="uppercase mt-3">Aparcabicicletas semivertical</h3>
              <p>
                Para aprovechar al máximo el espacio cuando escasea. El
                aparcabicicletas semivertical se puede utilizar en interiores o
                exteriores. La rueda trasera se puede asegurar al
                aparcabicicletas con un candado tipo D o una cadena...
              </p>
              <p className="shop-now">Solicitar cotización</p>
            </Link>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default BikeRacks;
